import React from 'react'
// import Search from './Search'
// import IssuesHistory from './IssuesHistory'
// import Status from './Status'
// import LanguageSwitcher from './LanguageSwitcher'
// import Actions from './Actions'
// import _ from 'lodash'
import { withRouter, matchPath } from 'react-router-dom'
import { connect } from 'react-redux'
import UserMenu from './UserMenu'
import style from './style.module.scss'

const mapStateToProps = ({ menu }) => ({
  menuData: menu.menuData,
})

@withRouter
@connect(mapStateToProps)
class TopBar extends React.Component {
  state = {
    activeSubmenu: '',
    activeItem: '',
  }

  currentLocation = ''

  componentDidMount() {
    this.setActiveItems(this.props)
  }

  componentWillReceiveProps(newProps) {
    const { pathname } = newProps.location
    if (this.currentLocation !== pathname) {
      this.setActiveItems(newProps)
      this.currentLocation = pathname
    }
  }

  setActiveItems = props => {
    const { menuData = [] } = props
    if (!menuData.length) {
      return
    }

    const flattenItems = (items, key, parent = null) => {
      let flattenedItems = []
      items.forEach(item => {
        if (parent !== null) item.parent = parent
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          flattenedItems = flattenedItems.concat(flattenItems(item[key], key, item))
        }
      })
      return flattenedItems
    }

    const activeItem = flattenItems(menuData, 'children').find(item => {
      return matchPath(props.location.pathname, { path: item.url, exact: item.isExact || true })
    })

    const activeSubmenuRef = flattenItems(menuData, 'children').find(item => {
      // console.log(activeItem, item)
      return activeItem && activeItem.key === item.key
    })

    let activeSubmenu = null
    
    if (activeSubmenuRef)
      activeSubmenu = activeSubmenuRef.visiable === false ? activeSubmenuRef.parent.parent : activeSubmenuRef.parent

    this.setState({
      activeItem: activeItem && activeItem.title,
      activeSubmenu: activeSubmenu && activeSubmenu.title,
    })
  }

  render() {
    const { activeItem, activeSubmenu } = this.state
    return (
      <div className={style.topbar}>
        <div className="mr-md-4 mr-auto">
          <ul className={`${style.breadcrumbs} mr-4`}>
            { activeSubmenu && 
            <li className={style.breadcrumb}>
              <a href="#" className={style.breadcrumbLink}>
                {activeSubmenu}
              </a>
            </li> 
            }
            <li className={style.breadcrumb}>
              <a href="#" className={`${style.breadcrumbLink} ${style.breadcrumbLink__current}`}>
                {activeItem}
              </a>
            </li>
          </ul>
          {/* <Search /> */}
        </div>
        <div className="mr-auto d-none d-md-block">{/* <IssuesHistory /> */}</div>
        <div className="mb-0 mr-4 d-xl-block d-none">{/* <Status /> */}</div>
        <div className="mr-4 d-none d-sm-block">{/* <LanguageSwitcher /> */}</div>
        <div className="mr-4 d-none d-sm-block">{/* <Actions /> */}</div>
        <div className="">
          <UserMenu />
        </div>
      </div>
    )
  }
}

export default TopBar
