const API_HOST = (() => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // console.log("dev:")// dev code
    // return 'http://taishinbank.makecode.io'
    // return 'http://211.75.90.65/console'
    // return 'http://192.168.100.3/console'
    return 'https://www.taishinlab.com.tw/console'
    // return 'http://59.120.14.163/console'
    // return 'http://192.168.100.131/console'
  }
  // console.log("production:")// production code
  return 'https://www.taishinlab.com.tw/console'

  // return 'http://192.168.100.3/console'
  // return 'http://59.120.14.163/console'
  // return 'http://211.75.90.65/console'
  // return 'http://192.168.100.131/console'
})()

// REGISTER, SCREEN, SPEAKER, ALL

const APP_TYPE = 'REGISTER'
const TABLE_LOCALE = {
  filterTitle: '篩選',
  filterConfirm: '確定',
  filterReset: '重置',
  emptyText: '暫無數據',
}
const DATEPICKER_LOCALE = {
  lang: {
    locale: 'en_US',
    placeholder: 'Select date',
    rangePlaceholder: ['Start date', 'End date'],
    today: 'Today',
    now: 'Now',
    backToToday: 'Back to today',
    ok: 'Ok',
    clear: 'Clear',
    month: 'Month',
    year: 'Year',
    timeSelect: 'Select time',
    dateSelect: 'Select date',
    monthSelect: 'Choose a month',
    yearSelect: 'Choose a year',
    decadeSelect: 'Choose a decade',
    yearFormat: 'YYYY',
    dateFormat: 'M/D/YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'M/D/YYYY HH:mm:ss',
    monthFormat: 'MMMM',
    monthBeforeYear: true,
    previousMonth: 'Previous month (PageUp)',
    nextMonth: 'Next month (PageDown)',
    previousYear: 'Last year (Control + left)',
    nextYear: 'Next year (Control + right)',
    previousDecade: 'Last decade',
    nextDecade: 'Next decade',
    previousCentury: 'Last century',
    nextCentury: 'Next century',
  },
  timePickerLocale: {
    placeholder: 'Select time',
  },
  dateFormat: 'YYYY-MM-DD',
  dateTimeFormat: 'YYYY-MM-DD HH:mm:ss',
  weekFormat: 'YYYY-wo',
  monthFormat: 'YYYY-MM',
}

export { API_HOST, TABLE_LOCALE, DATEPICKER_LOCALE, APP_TYPE }
