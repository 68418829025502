import { APP_TYPE } from 'configs/constants'

export default async function getMenuData() {
  let menu = []
  if (APP_TYPE === 'SPEAKER' || APP_TYPE === 'ALL') {
    menu = menu.concat([
      {
        title: '音箱設定',
        key: 'speaker',
        icon: 'fa fa-desktop',
        code: 'mgt:speaker',
        url: '/speaker',
      },
      {
        title: '自助體驗區外牆',
        key: 'screen1',
        icon: 'fa fa-desktop',
        code: 'mgt:screen:1',
        url: '/screen/1',
      },
      {
        title: '全家智慧咖啡',
        key: 'screen2',
        icon: 'fa fa-desktop',
        code: 'mgt:screen:2',
        url: '/screen/2',
      },
      {
        title: '用戶管理',
        key: 'manager',
        icon: 'fa fa-user',
        code: 'mgt:manager',
        url: '/manager',
      },
    ])
  }
  if (APP_TYPE === 'SCREEN' || APP_TYPE === 'ALL') {
    menu = menu.concat([
      {
        title: '多元支付屏幕',
        key: 'screen1',
        icon: 'fa fa-desktop',
        code: 'mgt:screen:1',
        url: '/screen/1',
      },
      {
        title: '辨識屏幕',
        key: 'screen2',
        icon: 'fa fa-desktop',
        code: 'mgt:screen:2',
        url: '/screen/2',
      },
      {
        title: '影像管理',
        key: 'user',
        icon: 'fa fa-users',
        code: 'mgt:user',
        url: '/photo',
      },
      {
        title: '密碼登記用戶',
        key: 'code',
        icon: 'fa fa-users',
        code: 'mgt:user',
        url: '/code',
      },
      {
        title: '密碼設定',
        key: 'password',
        icon: 'fa fa-unlock-alt',
        code: 'mgt:password',
        url: '/password',
      },
      {
        title: '歷史紀錄',
        key: 'history',
        icon: 'fa fa-rocket',
        code: 'mgt:access',
        url: '/history',
      },
      {
        title: '用戶管理',
        key: 'manager',
        icon: 'fa fa-user',
        code: 'mgt:manager',
        url: '/manager',
      },
    ])
  }
  return menu
}
