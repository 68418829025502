import { all, takeEvery, call, put } from 'redux-saga/effects'
import axios from 'axios'
import actions from './actions'
import { API_HOST } from '../../configs/constants'

// export function* SET_CONFIG([ configs ]) {
//   yield put({
//     type: 'settings/SET_STATE',
//     payload: configs,
//   })
// }

async function loadRemoteConfig() {
  const request = axios.create({
    crossDomain: true,
    async: true,
    headers: {
      'Access-Token': 'XXXXX',
    },
    timeout: 60000,
    withCredentials: false,
  })

  const url = `${API_HOST}/api/v1/system/config`
  return request
    .get(url, {})
    .then(response => response)
    .catch(error => {
      console.log('get config error in : ', error)
    })
}

export function* LOAD_CURRENT_CONFIG() {
  const response = yield call(loadRemoteConfig)

  const { status = 500, data = null } = response
  if (status === 200 && data != null) {
    const { code = 500 } = data
    const configs = data.data || {}

    if (code === 200) {
      console.log(configs)
      yield put({
        type: 'config/SET_CONFIG_STATE',
        payload: configs,
      })
    }
  }
}

export default function* rootSaga() {
  yield all([
    // takeEvery(actions.LOAD_CONFIG, SET_CONFIG),
    takeEvery(actions.LOAD_CONFIG, LOAD_CURRENT_CONFIG),
    LOAD_CURRENT_CONFIG(), // run once on app load to check user auth
  ])
}
