import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import style from './style.module.scss'

const mapStateToProps = ({ settings }) => ({ settings })

@connect(mapStateToProps)
class Footer extends React.Component {
  render() {
    const {
      settings: { isContentNoMaxWidth },
    } = this.props
    return (
      <div
        className={classNames(style.footer, {
          [style.footerFullWidth]: isContentNoMaxWidth,
        })}
      >
        <div className={style.inner}>
          <div className="row">
            <div className="col-md-8">
              {/* <p>
                <strong>空調先驅領導者 - DAIKIN大金空調!</strong>
              </p>
              <p>
                自1951 年於日本國內率先投入空調開發的領域以來，
                大金工業可以自豪地說這幾十年來的企業史正是一部活生生的空調技術發展史。身為世界頂尖的空調製造商，
                從冷媒的開發到空調機器的開發都擁有獨特的先進尖端技術，
                今後將以多年來苦心經營的「空調」及「化學」的優良技術為基礎磐石，
                更進一步地持續自我挑戰及創新發展。
              </p> */}

              <p>台新國際商業銀行 版權所有 &copy; 2020 Taishin Bank Inc. All Rights Reserved</p>
            </div>
            {/* <div className="col-md-4">
              <div className={style.logo}>
                <img src="resources/images/logo.png" alt="Air UI" />
                 <div className={style.logoName}>AIR UI</div>
                <div className={style.logoDescr}>Admin Template</div>
              </div>
              <div className={`${style.logo} mt-2`}>
                <img src="resources/images/daikin-logo.png" alt="Air UI" />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
