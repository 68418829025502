import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import style from './style.module.scss'

class System404 extends React.Component {
  render() {
    return (
      <div>
        <Helmet title="Comming Soon" />
        <div className={style.errors}>
          <div className="pt-4 pb-4 d-flex align-items-end mt-auto">
            <img src="resources/images/logo.png" alt="AIR UI Logo" />
            <div className="air__utils__logo__text">
              <div className="air__utils__logo__name text-uppercase text-dark font-size-21 mb-2">
                體驗館
              </div>
              <div className="air__utils__logo__descr text-uppercase font-size-12 text-gray-6">
                後台管理系統
              </div>
            </div>
          </div>
          <div className={`${style.container} pl-5 pr-5 pt-5 pb-5 mb-auto text-dark font-size-30`}>
            <div className="font-weight-bold mb-3">Page not found</div>
            <div>找不到頁面!!</div>
            <div className="font-weight-bold font-size-70 mb-1">☺☺☺ —</div>
            <Link to="/" className="btn btn-outline-primary width-100">
              Go Back
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default System404
