import actions from './actions'

const initialState = {
  synchronized: false,
}

export default function configReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_CONFIG_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
