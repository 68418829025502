import { notification } from 'antd'
import axios from 'axios'
import { API_HOST } from '../configs/constants'


export async function login(email, password, captcha, key) {

  const request = axios.create({
    crossDomain: true,
    async: true,
    timeout: 60000,
    withCredentials: false,
  })

  const url = `${API_HOST}/api/v1/manager/login`
  return request

    .post(url, { email, password, captcha, key })

    .then(response => {
      const { status = 500, data = null } = response
      if (status === 200 && data != null) {
        const { code = 500, reason } = data
        if (code !== 200) {

          const msg = reason.messages.email || reason.messages.captcha
          notification.warning({
            message: '登入失敗',
            description: msg.join(','),
          })
          return false
        }
        localStorage.setItem('manager', JSON.stringify(data.data))
        return true
      }
      return response
    })
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}

export async function currentAccount() {
  let profile = null
  try {
    profile = JSON.parse(localStorage.getItem('manager') || {})
  } catch (err) {
    return false
  }

  if (profile) {
    const request = axios.create({
      crossDomain: true,
      async: true,
      timeout: 60000,
      headers: {
        'Access-Token': profile.token,
      },
      withCredentials: false,
    })

    const url = `${API_HOST}/api/v1/manager/me`
    return request
      .post(url, {})
      .then(response => {
        const { status = 500, data = null } = response
        if (status === 200 && data != null) {
          const { code = 500 } = data
          if (code !== 200) {
            return null
          }
          localStorage.setItem('manager', JSON.stringify(data.data))
        }
        return data.data
      })
      .catch(error => {
        notification.warning({
          message: error.code,
          description: error.message,
        })
      })
  }
  return false
}

export async function logout() {
  // return firebaseAuth()
  //   .signOut()
  //   .then(() => true)
}
