import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import Switch from 'react-router-transition-switch'
import Loadable from 'react-loadable'
import { connect } from 'react-redux'

import Layout from 'layouts'
import Loader from 'components/layout/Loader'
import NotFoundPage from 'pages/system/comming'
import { APP_TYPE } from 'configs/constants'

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

let routes = []
if (APP_TYPE === 'REGISTER' || APP_TYPE === 'ALL') {
  routes = routes.concat([
    // 員工註冊
    {
      path: '/system/signup/employee',
      Component: loadable(() => import('pages/system/signup/employee')),
      exact: true,
    },
    // 訪客註冊
    {
      path: '/system/signup/visitor',
      Component: loadable(() => import('pages/system/signup/visitor')),
      exact: true,
    },
    // 訪客註冊
    {
      path: '/system/signup/fintech',
      Component: loadable(() => import('pages/system/signup/fintech')),
      exact: true,
    },
    // 密碼註冊
    {
      path: '/system/signup/code',
      Component: loadable(() => import('pages/system/signup/code')),
      exact: true,
    },
  ])
}

if (APP_TYPE === 'SCREEN' || APP_TYPE === 'ALL') {
  routes = routes.concat([
    // System Pages
    {
      path: '/system/login',
      Component: loadable(() => import('pages/system/login')),
      exact: true,
    },
    // 屏幕
    {
      path: '/screen/1',
      Component: loadable(() => import('pages/screen')),
      exact: true,
    },
    // 屏幕
    {
      path: '/screen/2',
      Component: loadable(() => import('pages/screen_third')),
      exact: true,
    },
    // 用戶
    {
      path: '/photo',
      Component: loadable(() => import('pages/user')),
      exact: true,
    },
    // 用戶
    {
      path: '/code',
      Component: loadable(() => import('pages/code')),
      exact: true,
    },
    // 密碼
    {
      path: '/password',
      Component: loadable(() => import('pages/password')),
      exact: true,
    },
    // 歷史紀錄
    {
      path: '/history',
      Component: loadable(() => import('pages/history')),
      exact: true,
    },
    {
      path: '/manager',
      Component: loadable(() => import('pages/manager')),
      exact: true,
    },
    {
      path: '/manager/:id',
      Component: loadable(() => import('pages/manager/item')),
      exact: true,
    },
    {
      path: '/system/404',
      Component: loadable(() => import('pages/system/404')),
      exact: true,
    },
    {
      path: '/system/500',
      Component: loadable(() => import('pages/system/500')),
      exact: true,
    },
  ])
}
if (APP_TYPE === 'SPEAKER' || APP_TYPE === 'ALL') {
  routes = routes.concat([
    // System Pages
    {
      path: '/system/login',
      Component: loadable(() => import('pages/system/login')),
      exact: true,
    },
    // 屏幕
    // 屏幕
    {
      path: '/screen/1',
      Component: loadable(() => import('pages/screen')),
      exact: true,
    },
    // 屏幕
    {
      path: '/screen/2',
      Component: loadable(() => import('pages/screen_second')),
      exact: true,
    },
    // 音箱
    {
      path: '/speaker',
      Component: loadable(() => import('pages/speaker')),
      exact: true,
    },
    {
      path: '/manager',
      Component: loadable(() => import('pages/manager')),
      exact: true,
    },
    {
      path: '/manager/:id',
      Component: loadable(() => import('pages/manager/item')),
      exact: true,
    },
    {
      path: '/system/404',
      Component: loadable(() => import('pages/system/404')),
      exact: true,
    },
    {
      path: '/system/500',
      Component: loadable(() => import('pages/system/500')),
      exact: true,
    },
  ])
}
const mapStateToProps = ({ settings }) => ({ settings })

@connect(mapStateToProps)
class Router extends React.Component {
  splice = (oldString, start, delCount, newSubStr) => {
    return oldString.slice(0, start) + newSubStr + oldString.slice(start + Math.abs(delCount))
  }

  render() {
    const {
      history,
      settings: { routerAnimation },
    } = this.props

    const isEmbedBrowser = /Line/.test(navigator.userAgent)
    const addon = window.location.href.indexOf('?') > -1 ? '&' : '?'
    console.log(isEmbedBrowser)
    if (isEmbedBrowser) {
      console.log(window.location.href)
      const newUrl = this.splice(
        window.location.href,
        window.location.href.indexOf('#'),
        0,
        `${addon}openExternalBrowser=1`,
      )
      window.location.href = newUrl
      console.log(window.location.href)
    }
    return (
      <ConnectedRouter history={history}>
        <Layout>
          <Switch
            render={props => {
              const {
                children,
                location: { pathname },
              } = props
              return (
                <SwitchTransition>
                  <CSSTransition
                    key={pathname}
                    classNames={routerAnimation}
                    timeout={routerAnimation === 'none' ? 0 : 300}
                  >
                    {children}
                  </CSSTransition>
                </SwitchTransition>
              )
            }}
          >
            <Route exact path="/" render={() => <Redirect to="/screen/1" />} />

            {routes.map(({ path, Component, exact }) => (
              <Route path={path} key={path} exact={exact}>
                <Component />
              </Route>
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </Layout>
      </ConnectedRouter>
    )
  }
}

export default Router
